@import '../../../../semantic-ui/variables.less';

.auction-component {
	height: 100%;
	background-color: @white-500;
	border: 1px solid @border-color;
	border-radius: 0 12px 12px 0;
	text-align: left;
	display: flex;
	flex-direction: column;
	margin: auto;
	vertical-align: middle;
	justify-content: center;
	font-size: 12px;
    padding: 15px 5px;

    &__row {
		padding: 0 7px;
		margin-bottom: 14px;
	}

	&__buttons-row {
		display: flex;
		justify-content: center;
		gap: 14px;
	}

	&__column:last-child {
		width: 100%;
	}

	&__text {
		display: block;
		margin-bottom: 4px;
		margin-left: 2px;
		font-size: 10px;
		font-weight: 400;
		line-height: 12px;

		&_small {
			font-size: 9px;
			line-height: 12px;
			font-weight: 400;
			margin-left: 5px;
			white-space: nowrap;
		}

		&_small:first-child {
			margin-bottom: 0;
		}
	}

	&__badge-wrapper {
		display: flex;
		.auction-component__column {
			align-self: end;
		}
	}

	&__badge {
		font-weight: 500;
		font-size: 12px;
		line-height: 12px;
		height: 31px;
		text-align: center;
		color: @dark-500;
		width: 100%;
		padding: 10px;
		border-radius: 4px;
		background-color: @gray-10;

		&_small {
			width: 31px;
			min-width: 31px;
			padding: 10px 0;
			margin: 0 5px;
		}
	}

	&__input {
		width: 100%;
		min-width: 92px;
	}

	&__input-icon {
		position: absolute;
		right: 0;
		width: 25px;
		height: 29px;
		border-left: 1px solid @border-color;
		padding-left: 8px;
		padding-top: 7px;

		&_success {
			border-color: @color-option-1;
		}

		&_danger {
			border-color: @color-option-3;
		}
	}

	&__date-input {
		padding-left: 5px;
		width: 100%;
		font-size: 13px;
	}

	&__button {
		width: 27px;
		height: 27px;
		cursor: pointer;

		&-bet {
			background-image: url('../../../../components/images/bet-button-icon.svg');
		}

		&-unbet {
			background-image: url('../../../../components/images/unbet-icon.svg');
		}
	}

	&__badge-popup {
		display: flex;
		cursor: pointer;
	}
}
