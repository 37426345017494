@import '../../../../semantic-ui/variables.less';

.custom-cell {
	&__id {
		margin: 0 auto;
		display: flex;
		justify-content: center;
	}

	&__link:hover {
		color: @primaryColor !important;
	}
}
