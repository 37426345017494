@import '../../../../semantic-ui/variables.less';

.req-item {
	height: 100%;
	background-color: white;
	border: 1px solid @border-color;
	border-radius: 12px 0 0 12px;
	margin: 0 10px 10px 0 !important;

	&__footer {
		padding: 7px 10px !important;
		border-top: 1px solid @border-color;
		justify-content: flex-end !important;
		gap: 10px;

		&-label {
			padding: 0 !important;
			margin: 0;
			.status{
				padding: 5px 0;
				font-size: 10px !important;
			}
		}
	}

	&__header {
		padding: 0 15px !important;
		display: flex;
		justify-content: center;
		border-bottom: 1px solid @border-color;
		height: 20%;
		min-height: 53px;
		font-size: 18px;
		font-weight: 400;
		align-items: center !important;

		&-checkbox {
			padding: 0 20px 0 1px;
		}

		&-title {
			line-height: 1.4;
		}

		&-uid {
			font-size: 13px;
			margin-left: auto;
			padding: 0 !important;
			text-align: right;
		}
	}

	&__body {
		height: 80%;
		padding: 5px 0 5px 0 !important;

		&-left {
			padding: 15px !important;
			border-right: 1px solid @border-color;
			height: 100%;
			display: flex !important;
			flex-direction: column;
			width: 42% !important;
			gap: 2px;
            justify-content: center;
		}

		&-right {
			padding: 25px !important;
			display: flex !important;
			justify-content: center;
			width: 58% !important;
            flex-direction: column;

			&-header {
				margin-bottom: 20px;
				font-weight: 400;
				font-size: 17px;
			}

			&-column {
				padding-left: 5px !important;
				padding-right: 5px !important;

				.badge {
					width: 100%;
					min-width: auto !important;
					margin: 0 !important;
				}

				.with-icon{
					.badge {
						width: calc(100% - 20px);
						border-radius: 4px 0 0 4px !important;
						display: block;
						text-align: right;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						padding-right: 0;
					}

					.text-icon .text-icon {
						font-weight: 400;
						font-size: 12px;
						text-align: center;
						color: #101513;
						width: 25px !important;
						border-radius: 0 4px 4px 0 !important;
						border: none !important;
						background-color: rgba(160, 169, 166, 0.1) !important;
						height: 32px !important;
					}
				}
			}
		}

		&-label {
			font-size: 11px;
			margin-bottom: 2px;
			white-space: nowrap;

			&:not(:first-child) {
				padding-top: 10px;
			}
		}

		&-badge-wrapper {
			margin-bottom: 7px;
			height: 32px;
			display: flex;
			white-space: nowrap;
			cursor: pointer;

			&_with-checkbox {
				padding-top: 8px;
				margin-left: 4px;
				display: flex;
				align-items: center;
				gap: 5px;
				cursor: help !important;
				white-space: wrap;
			}

			&:last-child {
				margin-bottom: 0;
			}

			&-icon {
				width: 19px;
				height: 19px;
				margin-top: 7px;
			}
		}

		&-checkbox-label {
			margin: 0;
			font-size: 10px;
			line-height: 1.5;
			font-weight: 400;
			white-space: initial;
		}

		&-link {
			font-size: 12px;
			padding-top: 19px;
			display: block;
			margin-left: 4px;
		}
	}

	&__location {
		display: flex;
		flex-direction: column;
		gap: 2px;

		&-icon {
			display: flex;
			gap: 11px;

			img {
				max-width: 24px;
			}
		}

		&-title {
			font-size: 16px;
  			overflow: hidden;
  			text-overflow: ellipsis;
			max-width: 250px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
  			-webkit-box-orient: vertical;
            max-height: 50px;
		}

		&-region {
			font-size: 11px;
			overflow: hidden;
			text-overflow: ellipsis;
			padding-left: 22.5px;
		}

		&-info {
			margin-left: 11.5px;
		}

		&:first-child &-info {
			height: 77px;
			border-left: 1px solid @dark-500 !important;
		}

		&-points {
			display: flex;
			align-items: center;
			padding-top: 17px;
			cursor: pointer;

			&-line {
				width: 50px;
				height: 1px;
				background: @dark-500;
			}

			&-circle {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				min-width: 23px;
				min-height: 23px;
				border-radius: 50%;
				background: @primary-500;
				color: @white-500;
				font-size: 14px;
				font-weight: 700;
				line-height: 17px;
				outline: @gray-20 solid 5px;
			}

			&-text {
				font-size: 10px;
				font-weight: 400;
				line-height: 11px;
				padding-left: 9px;
				white-space: nowrap;
			}
		}
	}
}

@media screen and (max-width: 1330px) {
	.req-item {
		margin-right: 0 !important;
	}
}
