@import '../../../../semantic-ui/variables.less';

.cost-form {
	margin: 10px 0 !important;

	&__wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	&__loader {
		position: relative;
		height: 110px;
	}

	&__content {
		&-row {
			margin: 0 !important;
			padding-top: 0 !important;
			padding-bottom: 14px !important;

			&:last-child {
				padding-bottom: 0 !important;
			}
		}

		&-column {
			font-size: 13px;
			font-weight: @label-regular-weight;
			line-height: 1.5;
			display: flex !important;

			&:first-child {
				display: flex !important;
				justify-content: flex-end;
				align-items: center;
			}

			&-field {
				width: 100%;
			}
		}
	}
}
