@import '../../semantic-ui/variables.less';

.page-content__container {
	display: flex;
	gap: 20px;

	.transport-wrapper {
		width: 73%;
	}

	.transport-filter-wrapper {
		width: 27%;

		.ui.menu {
			position: sticky;
			top: 20px;

			.content {
				overflow-x: visible !important;
			}
		}
	}
}
