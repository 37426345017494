@import '../../../../semantic-ui/variables.less';

.sidebar {
	width: 257px;
	background-color: @white-500;
	border-right: 1px solid @border-color;
	flex-shrink: 0;
	position: fixed;
	height: 100vh;
	margin-right: 30px;

	&__logo-container {
		height: 100px;
		border-bottom: 1px solid @border-color;
		display: flex;
		align-items: center;
		justify-content: center;

		&__logo {
			width: 180px;
			cursor: pointer;
		}

		&__logo-mini {
			width: auto;
			height: 60%;
			cursor: pointer;
			display: none;
		}
	}

	&__item {
		position: relative;
	}

	&__item-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		user-select: none;

		&:hover {
			& * .sidebar__text {
				color: @primary-500;
			}
		}

		&_active {
			& * .sidebar__text {
				color: @primary-500;
			}
		}
	}

	&__container {
		padding: 43px 32px 0 40px;
		display: flex;
		flex-direction: column;
		gap: 26px;
	}

	&__item-content {
		display: flex;
		gap: 16px;
		align-items: center;
	}

	&__text {
		color: @dark-80;
		font-size: 14px;
		font-weight: 700;
	}

	&__count {
		position: absolute;
		right: 0;
		top: 0;
		background-color: @secondary-500;
		border-radius: 4px;
		font-size: 10px;
		font-weight: 700;
		color: @white-500;
		padding: 4px 7px;
		line-height: 10px;

		&_active {
			background-color: @secondary-500;
		}
		&_claims {
			position: relative;
			left: 10px;
			background-color: @secondary-500;
			border-radius: 4px;
			font-size: 10px;
			font-weight: 700;
			color: @white-500;
			padding: 3px 7px;
			line-height: 10px;
		}
		&_quality {
			position: relative;
			right: 15px;
			background-color: @secondary-500;
			border-radius: 4px;
			font-size: 10px;
			font-weight: 700;
			color: @white-500;
			padding: 4px 7px;
			line-height: 10px;
		}
	}

	&__icon-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 12px;
	}

	&__submenu {
		overflow: hidden;
	}

	&__submenu-list {
		padding-top: 15px;
		padding-left: 7px;
		display: flex;
		flex-direction: column;
		gap: 12px;
		animation: open 0.3s ease-in-out;
	}

	&__submenu-item {
		font-size: 12px;
		font-weight: 600;
		cursor: pointer;
		text-decoration: none;
		list-style: none;

		img {
			width: 15px;
			height: 15px;
			vertical-align: middle;
		}

		&:hover {
			color: @primary-500;
		}

		&_active {
			color: @primary-500;
		}

		&__text {
			font-size: 12px;
			margin-left: 5px;
		}
	}

	&__chevron-icon {
		-webkit-transition: -webkit-transform 0.8s ease-in-out;
		-ms-transition: -ms-transform 0.8s ease-in-out;
		transition: transform 0.2s ease-in-out;

		&.up {
			transform: rotate(-180deg);
		}

		&.down {
			transform: rotate(0deg);
		}
	}
}

@keyframes open {
	from {
		margin-top: calc(-100% - 36px);
	}
	to {
		margin-top: 0;
	}
}

@media screen and (max-width: 1630px) {
	.sidebar {
		width: 200px;
		margin-right: 15px;

		&__container {
			padding: 43px 15px 0 15px;
		}
	}
}

@media screen and (max-width: 1280px) {
	.sidebar {
		width: 50px;
		margin-right: 0;

		&__container {
			padding: 43px 15px 0 15px;
		}

		&__text {
			display: none;
		}

		&__submenu-list {
			padding-left: 0;
		}

		&__submenu-item {
			&__text {
				display: none;
			}
		}

		&__count {
			position: relative;
			right: 5px;
		}

		&__chevron-icon {
			position: absolute;
			right: -8px;
		}

		&__logo-container {
			&__logo {
				display: none;
			}
			&__logo-mini {
				display: block;
			}
		}
	}
}
