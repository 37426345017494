@import '../../../../../semantic-ui/variables.less';

.custom-accreditation-form {
	border: 1px solid transparent;

	.table-container {
		&__table{
			padding-bottom: 0;
			border-bottom: 1px solid @border-color;
		}
	}

	&__loader {
		position: relative;
		height: 110px;
	}

	&__toggle {
		display: flex;

		margin-top: 16px;
		margin-bottom: 16px;
		margin-left: 30px;
		&__checkbox {
			margin-left: 10px;
		}
	}
	&__table-header {
		font-size: 13px;
		border: none !important;
		border-radius: 0 !important;

		thead {
			tr {
				th {
					background-color: @white-500 !important;
					padding: 14px 32px !important;

					&:first-child {
						text-align: center !important;
					}
				}
			}
		}

		tr {
			td {
				padding: 14px 32px !important;

				&:first-child {
					justify-content: center !important;
					font-weight: 600 !important;
				}
			}
		}

		& .table-container__table {
			padding-top: 0 !important;
		}

		& .ui.table tbody tr:last-child td {
			border-bottom: none !important;
		}
	}
}
